import index from '@/views/index/index.vue';
import store from '@/store'
import {POST, POST1} from '@/utils/api'
import router from '@/router/index'

let tools;
export default tools = {

    url:"https://www.ookan.com/pc%E5%8A%A9%E6%89%8B1.7.11.zip",

    functionPowers:[
        {
            title: "初始环境教程(必看)",
            name: "int",
            path: "/index/init",
            component: "index/init",
        },
        {
            title: "杀毒设置教程",
            name: "shaDu",
            path: "/index/shaDu",
            component: "index/shaDu",
        },
        {
            title: "基础教程",
            name: "jiChu",
            path: "/jiChu",
            children:[
                {
                    title: "多开教程",
                    name: "duoKai",
                    path: "/jiChu/duoKai",
                    component: "jiChu/duoKai",
                },
                {
                    title: "虚拟机教程",
                    name: "xuNiJi",
                    path: "/jiChu/xuNiJi",
                    component: "jiChu/xuNiJi",
                },
                {
                    title: "连续任务教程",
                    name: "jiHua",
                    path: "/jiChu/jiHua",
                    component: "jiChu/jiHua",
                },
            ]
        },
        {
            title: "日常周常教程",
            name: "riChang",
            path: "/riChang",
            children:[
                {
                    title: "团练教程",
                    name: "tuanLian",
                    path: "/riChang/tuanLian",
                    component: "riChang/tuanLian",
                },
                {
                    title: "授业教程",
                    name: "shouYe",
                    path: "/riChang/shouYe",
                    component: "riChang/shouYe",
                },
                {
                    title: "拉镖教程",
                    name: "laBiao",
                    path: "/riChang/laBiao",
                    component: "riChang/laBiao",
                },
                {
                    title: "江湖豪杰教程",
                    name: "haoJie",
                    path: "/riChang/haoJie",
                    component: "riChang/haoJie",
                },
                {
                    title: "永夜城教程",
                    name: "yongYeCheng",
                    path: "/riChang/yongYeCheng",
                    component: "riChang/yongYeCheng",
                },
                {
                    title: "踢馆教程",
                    name: "tiGuan",
                    path: "/zhouChang/tiGuan",
                    component: "zhouChang/tiGuan",
                },
                {
                    title: "凌霄城教程",
                    name: "lingXiaoCheng",
                    path: "/zhouChang/lingXiaoCheng",
                    component: "zhouChang/lingXiaoCheng",
                },
                {
                    title: "门禁教程",
                    name: "menJin",
                    path: "/zhouChang/menJin",
                    component: "zhouChang/menJin",
                },
            ]
        },
        {
            title: "隐士教程",
            name: "yinShi",
            path: "/yinShi",
            children:[
                {
                    title: "神机营",
                    name: "shenJiYing",
                    path: "/yinShi/shenJiYing",
                    component: "yinShi/shenJiYing",
                },
                {
                    title: "念萝坝",
                    name: "nianLuo",
                    path: "/yinShi/nianLuo",
                    component: "yinShi/nianLuo",
                },
                {
                    title: "天涯海阁",
                    name: "tianYa",
                    path: "/yinShi/tianYa",
                    component: "yinShi/tianYa",
                },
                {
                    title: "达摩派",
                    name: "daMo",
                    path: "/yinShi/daMo",
                    component: "yinShi/daMo",
                },
                {
                    title: "神水宫",
                    name: "shenShuiGong",
                    path: "/yinShi/shenShuiGong",
                    component: "yinShi/shenShuiGong",
                },
                {
                    title: "血刀堂",
                    name: "xueDao",
                    path: "/yinShi/xueDao",
                    component: "yinShi/xueDao",
                },
                {
                    title: "五仙教",
                    name: "wuXian",
                    path: "/yinShi/wuXian",
                    component: "yinShi/wuXian",
                },
                {
                    title: "星渺阁",
                    name: "xingMiao",
                    path: "/yinShi/xingMiao",
                    component: "yinShi/xingMiao",
                },
                {
                    title: "华山派",
                    name: "huaShan",
                    path: "/yinShi/huaShan",
                    component: "yinShi/huaShan",
                },
                {
                    title: "长风镖局",
                    name: "changFeng",
                    path: "/yinShi/changFeng",
                    component: "yinShi/changFeng",
                },
            ]
        },
        {
            title: "金乌岛教程",
            name: "jinWuDao",
            path: "/jinWuDao",
            children:[
                {
                    title: "剑魂挑战",
                    name: "tiaoZhan",
                    path: "/jinWuDao/jianHunTiaoZhan",
                    component: "jinWuDao/jianHunTiaoZhan",
                },
                {
                    title: "岚羲阁日常",
                    name: "lanYiGe",
                    path: "/jinWuDao/lanXiGe",
                    component: "jinWuDao/lanXiGe",
                },
                {
                    title: "廉山宗日常",
                    name: "lianShan",
                    path: "/jinWuDao/lianShanZong",
                    component: "jinWuDao/lianShanZong",
                },
                {
                    title: "五气堡日常",
                    name: "wuQi",
                    path: "/jinWuDao/wuQiBao",
                    component: "jinWuDao/wuQiBao",
                },
                {
                    title: "金沙滩日常",
                    name: "jinSha",
                    path: "/jinWuDao/jinShaTan",
                    component: "jinWuDao/jinShaTan",
                },
                {
                    title: "据点日常",
                    name: "juDian",
                    path: "/jinWuDao/juDian",
                    component: "jinWuDao/juDian",
                },
                {
                    title: "八目城",
                    name: "baMuCheng",
                    path: "/jinWuDao/baMuCheng",
                    component: "jinWuDao/baMuCheng",
                },
            ]
        },
        {
            title: "漠西教程",
            name: "moXi",
            path: "/moXi",
            children:[
                {
                    title: "红尘",
                    name: "hongChen",
                    path: "/moXi/hongChen",
                    component: "moXi/hongChen",
                },
                {
                    title: "黄泉",
                    name: "huangQuan",
                    path: "/moXi/huangQuan",
                    component: "moXi/huangQuan",
                },
                {
                    title: "碧落",
                    name: "biLuo",
                    path: "/moXi/biLuo",
                    component: "moXi/biLuo",
                },
                {
                    title: "进入/离开漠西",
                    name: "JinRumoXi",
                    path: "/moXi/JinRumoXi",
                    component: "moXi/JinRumoXi",
                },
            ]
        },
        {
            title: "工具教程",
            name: "gongju",
            path: "/gongju",
            children:[
                {
                    title: "挑战点兑换幸运币",
                    name: "tiaozhandianduihuan",
                    path: "/gongju/tiaozhandianduihuan",
                    component: "gongju/tiaozhandianduihuan",
                },
                {
                    title: "竞拍教程",
                    name: "jingPai",
                    path: "/gongju/jingPai",
                    component: "gongju/jingPai",
                },
            ]
        },
        {
            title: "生活教程",
            name: "shengHuo",
            path: "/shengHuo",
            children:[
                {
                    title: "弹琴",
                    name: "tanQin",
                    path: "/shengHuo/tanQin",
                    component: "shengHuo/tanQin",
                },
                {
                    title: "棋士",
                    name: "qiShi",
                    path: "/shengHuo/qiShi",
                    component: "shengHuo/qiShi",
                },
                {
                    title: "种地",
                    name: "zhongDi",
                    path: "/shengHuo/zhongDi",
                    component: "shengHuo/zhongDi",
                },
                {
                    title: "山庄种地",
                    name: "zhongDiSZ",
                    path: "/shengHuo/zhongDiSZ",
                    component: "shengHuo/zhongDiSZ",
                },
                {
                    title: "定点采集",
                    name: "dingdiancaiji",
                    path: "/shengHuo/dingdiancaiji",
                    component: "shengHuo/dingdiancaiji",
                },
                {
                    title: "地图采集",
                    name: "caiKuang",
                    path: "/shengHuo/caiKuang",
                    component: "shengHuo/caiKuang",
                },
                {
                    title: "钓鱼",
                    name: "diaoyu",
                    path: "/shengHuo/diaoyu",
                    component: "shengHuo/diaoyu",
                },
                {
                    title: "打猎",
                    name: "dalie",
                    path: "/shengHuo/dalie",
                    component: "shengHuo/dalie",
                },
                {
                    title: "养蚕",
                    name: "yangCan",
                    path: "/shengHuo/yangCan",
                    component: "shengHuo/yangCan",
                },

            ]
        },
    ],

    /**
     * 获取指定序号的列
     */
    getIndex(column, prop){
        for (let i = 0; i < column.length; i++) {
            if(column[i].prop == prop){
                return column[i]
            }
        }
    },

    /**
     * 校验table列的权限
     */
    checkTablePower(column, powerList=store.state.powerList){
        let pagesName = store.state.currRouter.name;
        for (let i = 0; i < powerList.length; i++) {
            if(pagesName == powerList[i].logicName) {
                for (let k = 0; k < column.length; k++) {
                    let flag = 0;
                    //循环判断是否有此列
                    for (let j = 0; j < powerList[i].tableFieldList.length; j++) {
                        if(powerList[i].tableFieldList[j].fieldLogicName && powerList[i].tableFieldList[j].isSelected && column[k].label){
                            let fieldLogicName = powerList[i].tableFieldList[j].fieldLogicName.replaceAll("_", "").toUpperCase();
                            let prop = column[k].prop.toUpperCase();
                            if (fieldLogicName == prop) {
                                flag++;
                            }
                        }
                    }
                    //如果没有此列,说明没有权限,visible设置false, 说明:show是控制显示列,visible控制是否有此列
                    if (flag == 0 && column[k].type != "selection" && column[k].type != "index" && column[k].type != "button") {
                        //isPower为false 不校验
                        if(column[k].isPower==false){

                        }else {
                            column[k].visible = false;
                        }
                    }
                }
            }
            if(powerList[i].powerList && powerList[i].powerList.length>0){
                column = this.checkTablePower(column, JSON.parse(JSON.stringify(powerList[i].powerList)))
            }
        }
        return column;
    },

    /**
     * 页面按钮鉴权指令
     * @description v-access="'001001'", 001001为权限码
     */
    accessDirective(app){
        app.directive('access', {
            mounted(el, binding) {
                if (!tools.getPowerCode(store.state.powerList, binding.value)){
                    el.remove();
                }
            },
        })
    },

    /**
     * 获取集合中是否存在指定code
     */
    getPowerCode(list, code){
        //没有code则表示有权限
        if(code == null || code == ""){
            return true;
        }
        for (let i = 0; i < list.length; i++) {
            if(list[i].powerCode == code && list[i].isSelected){
                return list[i];
            }
            if(list[i].powerList.length > 0){
                let temp = this.getPowerCode(list[i].powerList, code);
                if(temp){
                    return temp;
                }
            }
        }
        return null ;
    },

    /**
     * 获取集合中选中的ids
     */
    getPowerCheckedKeys(list, keys){
        list.forEach(item=>{
            if(item.isSelected){
                keys.push(item.id);
            }
            if(item.powerList.length > 0){
                keys = this.getPowerCheckedKeys(item.powerList,keys);
            }
        });
        return keys ;
    },

    /**
     * proxy对象转普通对象
     */
    getObjData(object){
        let form = {};
        Object.keys(object).forEach(key => {
            form[key] = object[key];
        });
        return form
    },

    /**
     * proxy对象转formData
     */
    getFormData(object){
        let formData = new FormData();
        Object.keys(object).forEach(key => {
            formData.append(key, object[key]);
        });
        return formData
    },

    /**
     * 获取页面查询limit
     */
    getLimit(page){
        let limitArr;
        try {
            limitArr = JSON.parse(localStorage.getItem("pageSize"));
            if(limitArr && limitArr.length > 0){
                for (let i = 0; i < limitArr.length; i++) {
                    if(limitArr[i].key == page){
                        return parseInt(limitArr[i].value)
                    }
                }
            }
        }catch (err) {
            console.log("limit解析错误", err)
        }
        return 15;
    },

    /**
     * 保存页面查询limit
     */
    setLimit(page,value){
        let limitArr;
        try {
            limitArr = JSON.parse(localStorage.getItem("pageSize"));
        }catch (err) {
            console.log("limit保存解析错误", err)
        }
        if(limitArr && limitArr.length > 0){
            limitArr.push({
                "key": page,
                "value": value,
            })
        }else{
            limitArr = [{
                "key": page,
                "value": value,
            }]
        }
        localStorage.setItem("pageSize", JSON.stringify(limitArr))
    },

    /**
     * 切换门店
     */
    switchShop(currShopId){
        let index = 0;
        if(store.state.companyInfo.shopList && store.state.companyInfo.shopList.length > 0){
            for (let i = 0; i < store.state.companyInfo.shopList.length; i++) {
                if(store.state.companyInfo.shopList[i].id == currShopId){
                    index = i;
                }
            }
            let shopInfo = store.state.companyInfo.shopList[index];
            shopInfo.currShop = index;
            store.commit("setShopInfo", shopInfo);
            localStorage.setItem("currShopId", currShopId);
        }else{
            let shopInfo = {
                companyId: -1,
                currShop: 0,
                id: -1,
                shopAddress: "",
                shopName: "暂无店铺",
                state: 0
            };
            store.commit("setShopInfo", shopInfo);
            localStorage.setItem("currShopId", -1);
        }
    },

    /**
     * 设置页面uuid
     */
    setActivePageUUID(){
        return new Promise((resolve, reject) => {
            if(store.state.activePageUUID){
                resolve();
            }else{
                //设置activePageUUID
                let uuid = this.getUUID();
                store.commit("setActivePageUUID", uuid);
                POST1("/author/EomsUser/setActivePage", {activePageUUID: uuid}, false ).then(res => {
                    resolve();
                }).catch(e =>{
                    reject();
                })
            }
        })
    },

    /**
     * 设置信息
     */
    setInfo: function(){
        return new Promise((resolve, reject) => {
            try {
                //初始化菜单
                this.initMenu(this.functionPowers);
                console.log("menuList", store.state)
                resolve();
            }catch (e) {
                reject(e);
            }
        });
    },

    /**
     * 初始化菜单
     */
    initMenu: function(functionPowers){
        if(!functionPowers){
            return;
        }
        //设置路由
        let menuList = [
            {
                name: 'home',
                path: '/home',
                meta: {
                    show: true,
                    title: '新手教程',
                    icon: 'el-icon-s-home'
                },
                component: () => import('@/views/index/home')
            },
        ];
        menuList = this.setMenu(menuList, functionPowers);
        let home = {
            path: '/',
            name: 'index',
            redirect: '/home',
            component: index,
            children: menuList
        };
        //添加
        router.addRoute(home);

        //添加到菜单
        store.commit("setMenuList", menuList);

    },

    /**
     * 设置菜单
     */
    setMenu(menuList, funcList){
        for (let i = 0; i < funcList.length; i++){
            let childList = [];
            if(funcList[i].children&&funcList[i].children.length>0){
                childList = this.setMenu(childList, funcList[i].children)
            }
            let item = {
                path: funcList[i].path,
                name: funcList[i].name,
                meta: {
                    show: true,
                    title: funcList[i].title,
                },
                children: childList
            };
            //如果有地址
            if(funcList[i].component){
                item.component = () => import("@/views/" + funcList[i].component)
            }
            menuList.push(item);
        }
        return menuList;
    },

    /**
     * 设置tabList
     */
    settingTabList: function(to){
        //设置当前路由,并存如缓存
        store.commit("setCurrRouter",to);
        localStorage.setItem('routerName',to.name);
        //判断tabList
        let tabList = store.state.tabList;
        let flag = 0;
        //循环判断,如果当前页已加入tab集合则不加入
        for (let i = 0; i < tabList.length; i++) {
            if(tabList[i].name == to.name){
                flag = 1;
            }
        }
        //如果跳转页面是一下页面,则不需要加入tab集合
        if(to.name == 'login' || to.name == 'home' || to.name == '404'){
            flag = 2;
        }
        if(flag === 0){
            tabList.push(to);
            store.commit("setTabList",tabList);
        }
    },

    /**
     * 分钟转时间
     */
    minToTime(val) {
        let time = [];
        let day = parseInt(val/60/24);
        let hour = parseInt(val/60%24);
        let min = parseInt(val%60);
        time[0] = day>0?day:'00';
        time[1] = hour>0?hour:'00';
        time[2] = min>0?min:'00';
        return time;
    },

    /**
     * 秒数转化为时分秒
     */
    formatSeconds(value) {
        let secondTime = parseInt(value);// 秒
        let minuteTime = 0;// 分
        let hourTime = 0;// 小时
        if(secondTime > 60) {//如果秒数大于60，将秒数转换成整数
            //获取分钟，除以60取整数，得到整数分钟
            minuteTime = parseInt(secondTime / 60);
            //获取秒数，秒数取余，得到整数秒数
            secondTime = parseInt(secondTime % 60);
            //如果分钟大于60，将分钟转换成小时
            if(minuteTime > 60) {
                //获取小时，获取分钟除以60，得到整数小时
                hourTime = parseInt(minuteTime / 60);
                //获取小时后取余的分，获取分钟除以60取余的分
                minuteTime = parseInt(minuteTime % 60);
            }
        }
        let result = secondTime > 9?secondTime: "0" + secondTime;
        result = (minuteTime > 9?minuteTime: "0" + minuteTime) + ":" + result;
        result = (hourTime > 9?hourTime: "0" + hourTime) + ":" + result;
        return result;
    },


    /**
     * 时间戳(10位)转时间格式
     */
    intervalTime(timeDiff) {
        // 计算小时数
        let hours = Math.floor(Math.floor(timeDiff) / (3600));
        // 计算去除小时剩余的秒
        let leavel1 = timeDiff % (3600);
        // 计算剩余的分钟数
        let minutes = Math.floor(leavel1 / (60));
        // 计算去除分钟剩余秒
        let second = Math.floor(leavel1 % (60));

        if (hours >= 0 && hours <= 9) {
            hours = "0" + hours;
        }
        if (minutes >= 0 && minutes <= 9) {
            minutes = "0" + minutes;
        }
        if (second >= 0 && second <= 9) {
            second = "0" + second;
        }
        return {
            hours: hours,
            minutes: minutes,
            second: second
        }
    },

    /**
     ** 加法函数，用来得到精确的加法结果
     ** 说明：javascript的加法结果会有误差，在两个浮点数相加的时候会比较明显。这个函数返回较为精确的加法结果。
     ** 调用：accAdd(arg1,arg2)
     ** 返回值：arg1加上arg2的精确结果
     **/
    accAdd: function(arg1, arg2) {
        arg1 = arg1?arg1:0;
        arg2 = arg2?arg2:0;
        var r1, r2, m, c;
        try {
            r1 = arg1.toString().split(".")[1].length;
        }
        catch (e) {
            r1 = 0;
        }
        try {
            r2 = arg2.toString().split(".")[1].length;
        }
        catch (e) {
            r2 = 0;
        }
        c = Math.abs(r1 - r2);
        m = Math.pow(10, Math.max(r1, r2));
        if (c > 0) {
            var cm = Math.pow(10, c);
            if (r1 > r2) {
                arg1 = Number(arg1.toString().replace(".", ""));
                arg2 = Number(arg2.toString().replace(".", "")) * cm;
            } else {
                arg1 = Number(arg1.toString().replace(".", "")) * cm;
                arg2 = Number(arg2.toString().replace(".", ""));
            }
        } else {
            arg1 = Number(arg1.toString().replace(".", ""));
            arg2 = Number(arg2.toString().replace(".", ""));
        }
        return (arg1 + arg2) / m;
    },

    /**
     ** 减法函数，用来得到精确的减法结果
     ** 说明：javascript的减法结果会有误差，在两个浮点数相减的时候会比较明显。这个函数返回较为精确的减法结果。
     ** 调用：accSub(arg1,arg2)
     ** 返回值：arg1加上arg2的精确结果
     **/
    accSub: function(arg1, arg2) {
        arg1 = arg1?arg1:0;
        arg2 = arg2?arg2:0;
        var r1, r2, m, n;
        try {
            r1 = arg1.toString().split(".")[1].length;
        }
        catch (e) {
            r1 = 0;
        }
        try {
            r2 = arg2.toString().split(".")[1].length;
        }
        catch (e) {
            r2 = 0;
        }
        m = Math.pow(10, Math.max(r1, r2)); //last modify by deeka //动态控制精度长度
        n = (r1 >= r2) ? r1 : r2;
        return ((arg1 * m - arg2 * m) / m).toFixed(n);
    },

    /**
     ** 乘法函数，用来得到精确的乘法结果
     ** 说明：javascript的乘法结果会有误差，在两个浮点数相乘的时候会比较明显。这个函数返回较为精确的乘法结果。
     ** 调用：accMul(arg1,arg2)
     ** 返回值：arg1乘以 arg2的精确结果
     **/
    accMul: function(arg1, arg2) {
        arg1 = arg1?arg1:0;
        arg2 = arg2?arg2:0;
        var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
        try {
            m += s1.split(".")[1].length;
        }
        catch (e) {
        }
        try {
            m += s2.split(".")[1].length;
        }
        catch (e) {
        }
        return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
    },

    /**
     ** 除法函数，用来得到精确的除法结果
     ** 说明：javascript的除法结果会有误差，在两个浮点数相除的时候会比较明显。这个函数返回较为精确的除法结果。
     ** 调用：accDiv(arg1,arg2)
     ** 返回值：arg1除以arg2的精确结果
     **/
    accDiv: function(arg1, arg2) {
        arg1 = arg1?arg1:0;
        arg2 = arg2?arg2:0;
        var t1 = 0, t2 = 0, r1, r2;
        try {
            t1 = arg1.toString().split(".")[1].length;
        }
        catch (e) {
        }
        try {
            t2 = arg2.toString().split(".")[1].length;
        }
        catch (e) {
        }
        r1 = Number(arg1.toString().replace(".", ""));
        r2 = Number(arg2.toString().replace(".", ""));
        return (r1 / r2) * pow(10, t2 - t1);
    },

    /**
     * 生成UUID
     */
    getUUID() {
        function S4() {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        }
        return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
    }


}
